<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        添加排班
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">门诊列表</span>
        </h5>
        <b-list-group class="list-group-filters">
          <b-list-group-item
            v-for="filter in taskFilters"
            :key="filter._id"
            @click="$emit('update:isEventHandlerSidebarActive', true)"
          >
            <feather-icon
              icon="MonitorIcon"
              size="18"
              class="mr-75"
            />
            <span class="align-text-bottom line-height-1">{{ filter.clinicname }}</span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,BListGroup,BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  data(){
    return {
      taskFilters: []
    }  
  },
  methods: {
      listclinic(){
        this.$api.clinic.list().then(res =>{
          this.taskFilters = res.data.data
        })
      },
  },
  created(){
    this.listclinic()
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BListGroup,
    BListGroupItem,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()
    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },
}
</script>

<style>

</style>

import request from "../utils/request";



//测试用
export function schedulinglist(calendars){
    return request({
        url:"/api/scheduling/list",
        method:"GET",
        params:{
            calendars
        }
    })
}

//获取当前用户的菜单   就用这个接口
export function createscheduling(data){
    return request({
        url:"/api/scheduling/createscheduling",
        method:"POST",
        data,
    })
}

//获取当前用户的菜单   就用这个接口
export function editscheduling(data){
    return request({
        url:"/api/scheduling/edit",
        method:"POST",
        data,
    })
}

//删除门诊
export function deletescheduling(id) {
    return request({
        url:"/api/scheduling/delete",
        method:"DELETE",
        params:{
            id,
        }
    })
}